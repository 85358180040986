* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  color: #8b8b8b;
}

body {
  background: auto;
  margin: 0 !important;
  padding: 0 !important;
  overflow: overlay;
}

.element {
  height: auto;
  width: auto;
  font-size: 45px;
  margin: 2%;
  padding-top: 2%;
  z-index: -1;
  flex-grow: initial;
}

.element.no-padding {
  padding-top: 0;
}

.active {
  color: #8b8b8b !important;
}

::-webkit-scrollbar {
  width: 25px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #f2aa4c;
}

::-webkit-scrollbar-thumb {
  background-color: #f2aa4c;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background-color: #f2aa4c;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #8b8b8b;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
